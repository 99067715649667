import { PAGE_CONTEXT as p } from 'Constants'
// TODO: move the contentCard image sizes into a contentCard theme file
const config = {
  name: 'base',
  group: '',
  apiKey: 'VGDliwxFpvN5tz8pfotOPg2OyZSuc4bl8Qyv1Fv73rS6HMKc',
  useMainMenu: false,
  useLoadMoreUI: true,
  useArticleMoreUI: true,
  useEmbedDemo: false,
  preloadCategoryImages: false,
  routes: {
    main: {
      page: 'Home',
      path: '/',
      exact: true
    },
    [p.HOME]: {
      page: 'Home',
      path: `${process.env.ANT_PREFIX || '/'}`,
      exact: true
    },
    [p.ARTICLE]: {
      page: 'ArticlePage',
      path: `${process.env.ANT_PREFIX}/article/:uuid/:slug`
    },
    [p.ARTICLE_LIST]: {
      page: 'ArticleListPage',
      path: `${process.env.ANT_PREFIX}/articles`,
      exact: true
    },
    [p.TREND]: {
      page: 'TrendPage',
      path: `${process.env.ANT_PREFIX}/trend/:uuid/:slug`
    },
    [p.TREND_LIST]: {
      page: 'TrendListPage',
      path: `${process.env.ANT_PREFIX}/trends`,
      exact: true
    },
    [p.INSTALOOK]: {
      page: 'InstalookPage',
      path: `${process.env.ANT_PREFIX}/instalook/:uuid/:slug`
    },
    [p.INSTALOOK_LIST]: {
      page: 'InstalookListPage',
      path: `${process.env.ANT_PREFIX}/instalooks`,
      exact: true
    },
    [p.PRODUCT]: {
      page: 'ProductPage',
      path: `${process.env.ANT_PREFIX}/product/:uuid/:slug`
    },
    [p.PRODUCT_LIST]: {
      page: 'ProductListPage',
      path: `${process.env.ANT_PREFIX}/products/:uuid/:subCategory`
    },
    [p.COMPARE]: {
      page: 'ComparePage',
      path: `${process.env.ANT_PREFIX}/compare/:uuid/:slug`
    },
    [p.OUTGOING]: {
      path: `${process.env.ANT_PREFIX}/outgoing?storeName=:storeName&to=:to`
    },
    [p.FAVOURITES]: {
      page: 'Favourites',
      path: `${process.env.ANT_PREFIX}/favourites`,
      exact: true
    }
  },
  userMaven: {
    enabled: false
  },
  amplitude: {
    enabled: false},
  favouritesEnabled: false,
  useCanonical: false,
  canonicalAlts: false,
  gaEnabled: true,
  instalooksEnabled: false,
  interStitialEnabled: false,
  altClickrefEnabled: false,
  showStoreNameOnProductCard: false,
  siteVerifyCode: '',
  trendsEnabled: false,
  cookieScript: '',
  showCookiePolicy: true,
  compareTitlePageLabel: 'Compare',
  articlesEnabled: false,
  hubsEnabled: false,
  heroArticleEnabled: false,
  useBreadcrumbs: true,
  useScrollButton: true,
  useMobileUI: false,
  siteWide: {
    useStickyNav: false,
    useExtraLinks: false,
    useExtraLinksDivider: false,
    useHomeIcon: true,
    useSearchBar: false,
    submitButtonText: 'Submit',
    submitButtonIcon: 'Search',
    searchPlaceholderText: 'Search'
  },
  contentListSkins: {
    homeTrends: 'Trend',
    trend: 'Trend',
    trendList: 'Trend',
    homeInstalooks: 'Instalooks',
    instalook: 'Instalooks',
    instalookList: 'Instalooks',
    smallArticle: 'smallArticle',
    hubTrend: 'Trend',
    productInstalooks: 'Instalooks'
  },
  relatedArticles: {
    articleCard: true
  },
  articleCard: {
    withExtraInfo: true,
    withSubTitle: true,
    showTaggedCategory: false
  },
  home: {
    useStoreTitle: false,
    panels: [],
    categories: {
      heading: 'Shop By Category'
    },
    stores: [],
    storesTitle: 'Brands We Love'
  },
  buyButton: {
    label: 'Buy',
    lowStockLabel: 'Low stock - buy now',
    useBuyButtonIcon: false
  },
  productModal: {
    title:
      "This product is now OUT OF STOCK - but we've found some alternatives"
  },
  articlesOnProductListPage: true,
  productList: {
    slots: {
      useContentListItem: true
    }
  },
  hub: {
    articles: {
      limit: 5
    },
    trends: {
      limit: 4
    },
    instalooks: {
      limit: 20
    }
  },
  articles: {
    useContentFilter: true,
    useExternalProductLinks: true,
    relatedLabel: 'Latest Articles',
    listHeading: 'Style Journal',
    listSubHeading:
      'Everything you need to know about fashion and lifestyle now.',
    homeArticles: {
      title: 'Latest Style Stories',
      intro: 'Everything you need to know about fashion and lifestyle now',
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 12
    },
    article: {
      title: 'Style Journal',
      intro: 'Everything you need to know about fashion and lifestyle now',
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 4
    },
    productPage: {
      title: 'Style Journal',
      intro: 'Everything you need to know about fashion and lifestyle now',
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 6
    },
    productAlt: 'You might also like...',
    productMore: 'Shop more matches',
    featuedProduct: {
      buttonText: 'Buy now'
    }
  },
  trends: {
    homeTrends: {
      title: 'Latest Trends'
    },
    productPage: {
      title: 'Shop The Latest Trends',
      intro: 'Discover even more of the latest trends for both men and women.',
      limit: 6,
      showMore: 'View all'
    },
    main: {
      title: 'Shop the latest trends',
      intro: '',
      withSub: false,
      withBtnIcon: false,
      withCta: true,
      showMore: 'View all',
      limit: 4
    },
    trend: {
      title: 'SHOP THE LATEST TRENDS',
      intro: 'Get your shopping fix with our curated product edits',
      withIntro: true,
      img: '',
      withBtnIcon: false,
      showMore: 'View all',
      limit: 4
    },
    secondary: {
      title: 'placeholder',
      withSub: false,
      intro: 'placeholder'
    },
    listHeading: 'Shop the latest trends',
    defaultTrendCategory: 'fashion'
  },
  instalooks: {
    relatedLabel: 'Latest Articles',
    listHeading: 'Celebrity Style',
    listSubHeading:
      'See it. Like it. Shop it. Discover the latest celebrity-inspired looks, as seen on the ’gram.',
    homeInstalooks: {
      title: 'Style Journal',
      intro: 'Everything you need to know about fashion and lifestyle now',
      limit: 4,
      withBtnIcon: false,
      showMore: 'View all'
    },
    productPage: {
      title: 'Shop by Celebrity',
      intro:
        'See it. Like it. Shop it. Discover the latest celebrity-inspired looks, as seen on the ’gram.',
      limit: 6,
      showMore: 'View all'
    },
    instalook: {
      title: 'Get The Celebrity Look',
      intro:
        'Like what you see? Discover even more of the latest celebrity-inspired looks.',
      limit: 5,
      showMore: 'View all'
    }
  },
  article: {
    useBreadcrumbs: false,
    useSectionLink: true,
    showTaggedCategory: false,
    showSponsoredBy: true,
    product: {
      showProductName: true
    },
    item: {
      image: {
        showExtraData: false
      },
      useOOSModal: true
    },
    metaData: {
      showInFooter: true
    }
  },
  productListNotFoundLabel: 'No Products Found. Sorry!',
  outOfStock: {
    title: 'OUT OF STOCK',
    description:
      'Unfortunately it looks like this product is no longer available. But don’t worry - we have curated some similar items below.',
    buttonLabel: 'See Similar Products',
    useSeeMoreButtonIcon: ''
  },
  productPage: {
    useFeaturedArticles: false
  },
  sectionLink: {
    article: {
      title: 'Shop',
      subTitle: 'The latest trends'
    },
    trend: {
      title: 'Discover',
      subTitle: 'The latest style stories'
    },
    productPage: {
      article: {
        title: 'Discover',
        subTitle: 'The latest style stories'
      },
      compare: {
        title: 'Search',
        subTitle: ''
      }
    }
  },
  topBarEnabled: false,
  productCards: {
    default: {
      small: 'PageLink',
      large: 'PageLink'
    },
    [p.COMPARE]: {
      small: 'AffiliateLink',
      large: 'AffiliateLink'
    }
  },
  sorting: {
    productListTitle: 'Sort By Price',
    lowLabel: 'low',
    highLabel: 'high',
    withToggleIcon: true
  },
  searchResults: {
    label: 'Results for: ',
  },
  filters: {
    category: [
      {
        name: 'mens',
        label: 'Men',
        uuid: '0b74ea8ebaf4'
      },
      {
        name: 'womens',
        label: 'Women',
        uuid: '22b3cf9f84e0'
      },
      {
        name: 'kids',
        label: 'Kids',
        uuid: '15e61cbb966e'
      }
    ],
    sale: {
      checkbox: false,
      label: 'Sale Items Only'
    },
    navPanel: {
      triggerText: 'Filter & Sort'
    },
    topLevelCategories: true,
    parentCategories: true,
    departments: {
      enabled: false
    },
    colors: {
      enabled: true
    },
    price: {
      belowLabel: 'Below',
      currencySymbol: '£',
      title: 'Price Range',
    },
    extendedStore: {
      placeholder: 'Search store',
    },
  },
  appliedFilters: {
    title: 'Applied filters',
    clearText: 'Clear all'
  },
  pagination: {
    articleList: 40,
    instalookList: 16,
    trendList: 25,
    productList: 32,
    altProducts: 4,
    compare: 23,
    productListArticles: 3,
    hubList: 3
  },
  helmet: {
    noIndex: {
      all: false
    },
    metaTitleFormat: {
      journeySeparator: '|',
      [p.HOME]: 'Shopping | {{PARTNER_NAME}}',
      [p.PRODUCT]: '{{PRODUCT_TITLE}} | {{PRODUCT_PRICE}} | {{PARTNER_NAME}}',
      [p.PRODUCT_LIST]: '{{JOURNEY}} | {{PARTNER_NAME}}',
      [p.COMPARE]: '{{PRODUCT_TITLE}} | Compare | {{PARTNER_NAME}}',
      [p.ARTICLE]: '{{ARTICLE_TITLE}} | {{PARTNER_NAME}}',
      [p.ARTICLE_LIST]: 'Latest Shopping Articles | {{PARTNER_NAME}}',
      [p.HUB]: '{{HUB_TITLE}} | {{PARTNER_NAME}}',
      [p.TREND]: '{{TREND_TITLE}} | {{PARTNER_NAME}}',
      [p.TREND_LIST]: 'Latest Shopping Trends | {{PARTNER_NAME}}',
      [p.INSTALOOK]: '{{INSTALOOK_TITLE}} | {{PARTNER_NAME}}',
      [p.INSTALOOK_LIST]: 'Latest Shopping Trends | {{PARTNER_NAME}}',
      [p.ARTICLE_NOT_FOUND]: 'Article Not Found | {{PARTNER_NAME}}',
      [p.NOT_FOUND]: '404 Page Not Found | {{PARTNER_NAME}}',
      [p.FAVOURITES]: 'Your favourites | {{PARTNER_NAME}}'
    },
    partner: 'helmet partner name',
    articles: {
      title: '',
      description: ''
    },
    defaultDescription: 'Default Description'
  },
  storeMap: {},
  storesEnabled: true,
  heroPanel: {},
  heroEnabled: false,
  heroTitle: 'Welcome',
  heroCopy: [''],
  heroImage: 'https://ik.imagekit.io/sqhmihmlh/bw/BW_Header_zvxwhh.jpg',
  shopPath: process.env.ANT_PREFIX,
  stores: [],
  compareCategoryLinksEnabled: true,
  categorySlotText: 'See More',
  compareTitle: 'You might also like...',
  compareLinkLabel: 'See Similar Products',
  bodyClass: 'html theme--dark',
  useSeeMoreButtonIcon: '',
  descriptionLabel: 'Product Information',
  descriptionLabelOutOfStock: 'Out Of Stock',
  descriptionTextOutOfStockText:
    'Unfortunately it looks like this product is no longer available. But don’t worry - we have curated some similar items below.',
  loading: {
    [p.COMPARE]: 8,
    [p.PRODUCT_LIST]: 8,
    [p.ARTICLE_LIST]: 8,
    [p.TREND_LIST]: 8
  },
  searchAndNotFound: {
    categoryTitle: 'Browse Top Categories',
    trendsTitle: 'DISCOVER STYLE STORIES',
    articlesTitle: 'SHOP THE LATEST TRENDS'
  },
  amplitude: {
    enabled: false,
    types: {
      clicks: {
        buyButton: 'Product Page - Click to Retailer',
        articleToRetailer: 'Article page - Click to Retailer',
        articleButtonClick: 'Article page - button click'
      },
      pageView: {
        home: 'Viewed Home Page',
        article: 'Viewed Article Page',
        articleList: 'Viewed ArticleList Page',
        productList: 'Viewed Category Page',
        product: 'Viewed Product Page',
        compare: 'Viewed Compare Page',
        trend: 'Viewed Trend Page',
        trendList: 'Viewed Trend list Page'
      }
    }
  },
  lotame: {
    enabled: false
  },
  blockUserAgents: [
    /Mozilla\/5\.0 \(compatible; YandexBot\/3\.0; \+http:\/\/yandex.com\/bots\)/,
    /Mozilla\/5\.0 \(Linux; Android 7\.0;\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; PetalBot;\+https:\/\/aspiegel\.com\/petalbot\)/,
    /Mozilla\/5\.0 \(Linux; Android 7\.0;\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; PetalBot;\+http:\/\/aspiegel\.com\/petalbot\)/,
    /Mozilla\/5\.0 \(compatible; Adsbot\/3\.1\)/,
    /Mozilla\/5\.0 \(compatible; Yeti\/1\.1; \+http:\/\/naver\.me\/spd\)/,
    /newspaper\/0\.2\.8/,
    /Mozilla\/5\.0 \(iPhone; CPU iPhone OS 8_1 like Mac OS X\) AppleWebKit\/600\.1\.4 \(KHTML, like Gecko\) Version\/8\.0 Mobile\/12B411 Safari\/600\.1\.4 \(compatible; YandexMobileBot\/3\.0; \+http:\/\/yandex\.com\/bots\)/,
    /Mozilla\/5\.0 \(Linux; Android 5\.0\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; Bytespider; https:\/\/zhanzhang\.toutiao\.com\/\)/,
    /Mozilla\/5\.0 \(Windows NT 10\.0; Win64; x64\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/83\.0\.4103\.106 Safari\/537\.36/,
    /Mozilla\/5\.0 \(compatible; Bytespider; spider-feedback@bytedance.com\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/70\.0\.0\.0 Safari\/537\.36/,
    /Mozilla\/5\.0 \(Linux; Android 5\.0\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; Bytespider; spider-feedback@bytedance\.com\/\)/,
    /Mozilla\/5\.0 \(Linux; Android 5\.0\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Mobile Safari\/537\.36 \(compatible; Bytespider; spider-feedback@bytedance\.com\)/,
    /Mozilla\/5.0 \(Macintosh; Intel Mac OS X 10_10_1\) AppleWebKit\/600\.2\.5 \(KHTML, like Gecko\) Version\/8\.0\.2 Safari\/600\.2\.5 \(Amazonbot\/0\.1; \+https:\/\/developer\.amazon\.com\/support\/amazonbot\)/,
    /Mozilla\/5\.0 AppleWebKit\/537\.36 \(KHTML, like Gecko; compatible; ClaudeBot\/1\.0; \+claudebot@anthropic.com\)/,
    /Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_13_6\) AppleWebKit\/537\.36 \(KHTML, like Gecko\) Chrome\/91\.0\.4472\.114 Safari\/537\.36/,
    /Mozilla\/5\.0 \(Macintosh; Intel Mac OS X 10_10_1\) AppleWebKit\/600\.2\.5 \(KHTML, like Gecko\) Version\/8\.0\.2 Safari\/600\.2\.5 \(Amazonbot\/0\.1; \+https\:\/\/developer\.amazon\.com\/support\/amazonbot\)/,
    /Mozilla\/5\.0 \(compatible; BLEXBot\/1\.0; \+http\:\/\/webmeup-crawler\.com\/\)/,
    /Turnitin \(https\:\/\/bit\.ly\/2UvnfoQ\)/,
    /Mozilla\/5\.0 AppleWebKit\/537\.36 \(KHTML, like Gecko; compatible; ClaudeBot\/1\.0; \+claudebot@anthropic\.com\)/,
    /meta-externalagent\/1\.1 \(\+https\:\/\/developers\.facebook\.com\/docs\/sharing\/webmasters\/crawler\)/
  ],
  productImgPlaceholder:
    'https://ik.imagekit.io/sqhmihmlh/general/300x300_text_PRODUCT_20IMAGE.png',
  storesBlacklist: [],
  navPanelCategoryData: null
}

export default config
